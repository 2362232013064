<template>
  <div id="warning">
    <div class="contentTop" v-if="getUserRole('risk_collect')">
      <div class="title test1">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        风险洞察
      </div>
      <div class="pl-4 pr-4">
        <div class="row justify-content-between">
          <div class="left">
            <div class="riskLeft">
              <div class="title2">
                风险评估结论
                <el-tag
                  class="ml-2"
                  style="color: #fff; font-size: 14px; border-color: #3ebb9f"
                  color="#3EBB9F"
                  v-show="grade.risk == '风险低'"
                  >风险低</el-tag
                >
                <el-tag
                  class="ml-2"
                  style="color: #fff; font-size: 14px; border-color: #f88e36"
                  color="#F88E36"
                  v-show="grade.risk == '风险中'"
                  >风险中</el-tag
                >
                <el-tag
                  class="ml-2"
                  style="color: #fff; font-size: 14px; border-color: #ee5043"
                  color="#EE5043"
                  v-show="grade.risk == '风险高'"
                  >风险高</el-tag
                >
              </div>
              <div class="row align-items-center">
                <el-tooltip
                  effect="dark"
                  content="上年同期风险评估结果"
                  placement="bottom"
                >
                  <div
                    class="p_box"
                    style="background:#D8E7FF; color: #3A85FD;}"
                  >
                    {{ grade_last.risk }}
                  </div>
                </el-tooltip>
                <div
                  class="p_box ml-1 mr-1"
                  style="background: #a6d0fe; color: #fff"
                >
                  <img
                    src="~@/assets/img/page/right.png"
                    alt=""
                    srcset=""
                    style="width: 30px; height: 20px"
                  />
                </div>
                <el-tooltip
                  effect="dark"
                  content="当期风险评估结果"
                  placement="bottom"
                >
                  <div class="p_box" style="background: #5797fd; color: #fff">
                    {{ grade.risk }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="text-center">
              <img
                v-show="grade.grade == 'A'"
                src="@/assets/img/detail/A.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'A-'"
                src="@/assets/img/detail/A-.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'A+'"
                src="@/assets/img/detail/A+.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'B'"
                src="@/assets/img/detail/B.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'B-'"
                src="@/assets/img/detail/B-.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'B+'"
                src="@/assets/img/detail/B+.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'C'"
                src="@/assets/img/detail/C.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'C-'"
                src="@/assets/img/detail/C-.png"
                alt=""
              />
              <img
                v-show="grade.grade == 'C+'"
                src="@/assets/img/detail/C+.png"
                alt=""
              />
            </div>
          </div>

          <div class="centerContent">
            <div class="pie" ref="pie"></div>
          </div>
          <div class="right" ref="bar"></div>
        </div>

        <div class="textDesc">
          {{ grade.desc }} 针对{{ company_name }}进行分析，共发现
          <span style="color: #f14c5d; font-size: 24px; font-weight: 550">{{
            dataList.重点关注.length +
            dataList.需要关注.length +
            dataList.提示信息.length
          }}</span>
          个风险，其中
          <span style="color: #f14c5d; font-size: 24px; font-weight: 550">{{
            dataList.重点关注.length
          }}</span>
          个重点关注、
          <span style="color: #f88e36; font-size: 24px; font-weight: 550">{{
            dataList.需要关注.length
          }}</span>
          个需要关注、
          <span style="color: #3a85fd; font-size: 24px; font-weight: 550">{{
            dataList.提示信息.length
          }}</span>
          个提示信息。
        </div>
      </div>

      <div class="content_text" v-show="dataList.重点关注.length != 0">
        <div class="mt-3">
          <div
            style="font-size: 18px; color: #333; font-weight: 550"
            class="mt-1 ml-2 mb-2"
          >
            重点关注如下：
          </div>
          <div class="row contentTag">
            <a
              class="tag"
              v-for="(item, index) in dataList.重点关注"
              :key="index"
            >
              {{ item[1] }}
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="row mt-3">
            <div style="font-size:16px;color:#333;width: 8%;" class="mt-1">
              需要关注：
            </div>
            <div class="row contentTag">
              <a class="tags" v-for="item in dataList.需要关注" :key="item">
                {{ item[1] }}
              </a>
            </div>
          </div>
          <div class="row mt-3">
            <div style="font-size:16px;color:#333;width: 8%;" class="mt-1">
              提示信息：
            </div>
            <div class="row contentTag">
              <a class="tagss" v-for="item in dataList.提示信息" :key="item">
                {{ item[1] }}
              </a>
            </div>
          </div> -->

      <!-- <div class="btn">
        <el-button v-if="!flagFive" type="text" @click="flagFive = true"
          >展开详情
          <img
            style="margin-top: -5px"
            src="@/assets/img/arrow/icon_button.svg"
        /></el-button>
        <el-button v-else type="text" @click="flagFive = false"
          >收起详情
          <img style="margin-top: -5px" src="@/assets/img/arrow/icon_top.svg"
        /></el-button>
      </div> -->
    </div>
    <!-- v-show="flagFive" -->
    <div class="tableBox test1">
      <div class="title" v-if="getUserRole('risk_detail')">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        风险洞察明细
      </div>
      <div v-if="getUserRole('risk_detail')">
        <div v-for="(item, key, index) in TableData" :key="index">
          <div class="row align-items-center mt-3 mb-3 text-left">
            <div class="boxt"></div>
            <div class="titleBox">{{ key }} {{ item.length }}</div>
          </div>
          <el-table
            :data="item"
            stripe
            :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
            style="width: 100%; font-size: 16px"
          >
            <el-table-column
              type="index"
              label="序号"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="关注点"
              width="200"
            ></el-table-column>
            <el-table-column prop="brief" label="财务表现"></el-table-column>
            <el-table-column prop="rlevel" label="风险等级" width="200">
              <template slot-scope="{ row }">
                <el-tag
                  v-show="row.rlevel == 1"
                  style="color: #3a85fd; border-color: #3a85fd; font-size: 12px"
                  effect="plain"
                  >提示信息</el-tag
                >
                <el-tag
                  v-show="row.rlevel == 2"
                  style="color: #ffa105; border-color: #ffa105; font-size: 12px"
                  effect="plain"
                  >需要关注</el-tag
                >
                <el-tag
                  v-show="row.rlevel == 3"
                  style="color: #f14c5d; border-color: #f14c5d; font-size: 12px"
                  effect="plain"
                  >重点关注</el-tag
                >
                <el-tag
                  v-show="row.triggered_continuously"
                  style="color: #996cff; border-color: #996cff; font-size: 12px"
                  effect="plain"
                  class="ml-1"
                  >连续出现</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column prop="desc" label="风险提示"></el-table-column>
          </el-table>
        </div>
      </div>

      <div v-if="getUserRole('custom_risk')">
        <div class="row align-items-center mt-3 mb-3 text-left">
          <div class="boxt"></div>
          <div class="titleBox">自定义风险点 {{ RuleData.pass.length }}</div>
        </div>
        <el-table
          :data="RuleData.pass"
          :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
          style="width: 100%; font-size: 16px"
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="关注点"
            width="200"
          ></el-table-column>
          <el-table-column label="风险等级" width="200">
            <template slot-scope="{ row }">
              <el-tag
                v-show="row.level == '0'"
                style="color: #3a85fd; border-color: #3a85fd; font-size: 12px"
                effect="plain"
                >提示信息</el-tag
              >
              <el-tag
                v-show="row.level == '1'"
                style="color: #ffa105; border-color: #ffa105; font-size: 12px"
                effect="plain"
                >需要关注</el-tag
              >
              <el-tag
                v-show="row.level == '2'"
                style="color: #f12c5d; border-color: #f14c5d; font-size: 12px"
                effect="plain"
                >重点关注</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            label="风险提示"
          ></el-table-column>
        </el-table>
        <div class="mt-2">
          <div
            style="font-size: 16px; color: #f12c5d"
            class="mt-1"
            v-for="item in RuleData.errors"
            :key="item"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  proFile,
  profile_detail,
  checkpoint,
  logActivity,
  rule_fails,
} from "@/api";
import * as sesstion from "../../../store/localStorage";
export default {
  name: "风险洞察",
  data() {
    return {
      // flagFive: true,
      dataList: {},
      TableData: [],
      grade: {
        desc: "报表中反映的风险点数量及程度重点关注",
        grade: "C",
        risk: "风险高",
        score: 0,
      },
      grade_last: {
        desc: "报表中反映的风险点数量及程度重点关注",
        grade: "C",
        risk: "风险高",
        score: 0,
      },
      chart: {},
      company_name: "",
      RuleData: {},
    };
  },

  mounted() {
    // this.init()
  },
  created() {
    this.$VM[this.$route.name] = this;
  },

  methods: {
    search(param) {
      checkpoint({
        company_code: param.company_code,
        menu_id: "8",
        parsing_time: param.year,
        industry_type: param.industry_name
          ? param.industry_name.split("/").reverse()[0]
          : "",
        industry_number: param.industry_code,
        industry_range: "0",
        report_time: param.report_time,
        report_type: param.report_type,
      }).then(({ data }) => {
        if (data.Status !== "1") {
          this.$loadingHide(); //隐藏加载动画
          return this.$alert(data.Message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        } else {
          logActivity({
            company_code: param.company_code,
            menu_id: "8",
            parsing_time: param.year,
            industry_type: param.industry_name
              ? param.industry_name.split("/").reverse()[0]
              : "",
            industry_number: param.industry_code,
            industry_range: "0",
            report_time: param.report_time,
            report_type: param.report_type,
          });
          this.getData(param);
        }
      });
    },

    getData(param) {
      // console.log(param);
      this.$loadingShow(); //显示加载动画
      delete param.stock_code;
      delete param.name;
      delete param.industry_name;
      if (this.getUserRole("custom_risk")) {
        rule_fails({
          ...param,
          style: 0,
        }).then(({ data }) => {
          // console.log(data);
          this.RuleData = data;
          this.$loadingHide();
        });
      }
      if (this.getUserRole("risk_collect")) {
        proFile(param).then(({ data }) => {
          this.dataList = data.summary;

          this.grade = data.grade;
          this.grade_last = data.grade_last;
          this.chart = data.chart;
          setTimeout(() => {
            this.getPie(data.detail);
            this.getBar();
            this.$loadingHide();
          }, 1000);
        });
      }
      if (this.getUserRole("risk_detail")) {
        profile_detail(param).then(({ data }) => {
          this.TableData = data.detail;
          this.$loadingHide();
        });
      }
    },
    getPie(data) {
      let list = [];
      var total = 0;
      for (let key in data) {
        let obj = {};
        obj.name = key;
        obj.value = data[key].length;
        list.push(obj);
      }
      for (var i = 0; i < list.length; i++) {
        total += list[i].value;
      }
      var option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#3E93FF", "#F7882B", "#FAD33F", "#3EBB9F", "#8886F3"],
        title: [
          {
            show: true,
            text: "个",
            top: "47%",
            left: "54%",
            textStyle: {
              color: "#333",
              fontSize: 18,
            },
          },
          {
            show: true,
            text: total, //总计数
            top: "45%",
            left: "center",
            textStyle: {
              color: "#3E93FF",
              fontSize: 30,
            },
          },
        ],
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              formatter: function (param) {
                return (
                  param.name + ":\n" + param.percent + "% (" + param.value + ")"
                );
              },
              lineHeight: 20,
              fontSize: 16,
            },

            labelLine: {
              length: 20,
            },
            data: list,
          },
        ],
      };
      this.$echarts.init(this.$refs.pie).setOption(option);
      this.$loadingHide();
    },
    getBar() {
      let one = [];
      let two = [];
      let thr = [];
      this.chart.series[2].data.map((item) => {
        if (item == 0) {
          one.push("");
        } else {
          one.push(item);
        }
      });
      this.chart.series[1].data.map((item) => {
        if (item == 0) {
          two.push("");
        } else {
          two.push(item);
        }
      });
      this.chart.series[0].data.map((item) => {
        if (item == 0) {
          thr.push("");
        } else {
          thr.push(item);
        }
      });
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        color: ["#519DFF", "#F79340", "#f25D6D"],
        legend: {
          icon: "circle",
          top: "8%",
          textStyle: {
            fontSize: 16,
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "5%",
          top: "20%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          minInterval: 1,
          show: true,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 16,
          },
        },
        yAxis: {
          type: "category",
          data: this.chart.kinds,
          axisLabel: {
            fontSize: 16,
          },
        },
        series: [
          {
            name: "提示信息",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              fontSize: 16,
              color: "#333",
            },
            emphasis: {
              focus: "series",
            },
            data: one,
          },
          {
            name: "需要关注",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              fontSize: 16,
              color: "#333",
            },
            emphasis: {
              focus: "series",
            },
            data: two,
          },
          {
            name: "重点关注",
            type: "bar",
            stack: "total",
            label: {
              show: true,
              fontSize: 16,
              color: "#333",
            },
            emphasis: {
              focus: "series",
            },
            data: thr,
          },
        ],
      };

      this.$echarts.init(this.$refs.bar).setOption(option);
      this.$loadingHide();
    },
  },
};
</script>

<style lang="less" scoped>
#warning {
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 30px;
  margin-top: 10px;
  border-radius: 4px;

  .title2 {
    font-size: 18px;
    color: rgb(51, 51, 51);
    font-weight: 550;
    // margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .contentTop {
    width: 100%;
    background: #fff;
    margin-top: 10px;
    // padding: 10px 20px;
    .content_text {
      padding: 25px;

      .contentTag {
        flex-wrap: wrap;
        width: 90%;

        .tag {
          display: inline-block;
          height: 30px;
          background: rgba(241, 76, 93, 0.12);
          border-radius: 4px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 15px;
          border: none;
          line-height: 30px;
          color: #f14c5d;
          margin-right: 15px;
          margin-bottom: 15px;
        }

        .tags {
          display: inline-block;
          height: 30px;
          background: rgba(248, 142, 54, 0.12);
          border-radius: 4px;
          padding-left: 5px;
          border: none;
          padding-right: 5px;
          line-height: 30px;
          font-size: 14px;
          color: #f88e36;
          margin-right: 15px;
          margin-bottom: 15px;
        }

        .tagss {
          display: inline-block;
          height: 30px;
          background: rgba(54, 138, 248, 0.12);
          border-radius: 4px;
          padding-left: 5px;
          border: none;
          padding-right: 5px;
          line-height: 30px;
          font-size: 14px;
          color: #3a85fd;
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }
    }

    .title {
      font-size: 20px;
      font-weight: 550;
      background: #fff;
      padding: 15px 0px 0px 20px;
      color: #333;
    }

    .left {
      width: 25%;

      .riskLeft {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .p_box {
          border: none;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
          width: 60px;
          height: 20px;
          transform: skew(-25deg);
          border-radius: 3px;
        }
      }

      .bl {
        width: 110px;
        height: 18px;
        background: rgb(219, 219, 219);
        margin: 3px auto;

        .color_b {
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            to right,
            rgb(182, 209, 250),
            #3a85fd
          );
        }

        .color_o {
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            to right,
            rgb(250, 218, 182),
            #fda23a
          );
        }

        .color_r {
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            to right,
            rgb(250, 182, 188),
            #fd3a54
          );
        }
      }
    }

    .centerContent {
      width: 30%;
      padding-top: 45px;

      .pie {
        width: 100%;
        height: 350px;
      }
    }

    .right {
      width: 44%;
      height: 404px;
    }

    .textDesc {
      background: #e9f3ff;
      width: 100%;
      min-height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #333;
      padding: 10px;
      margin-top: 10px;
    }

    .btn {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;

      .el-button {
        font-size: 16px;
        font-weight: 550;
      }
    }
  }
  .tableBox {
    margin-top: 20px;
    width: 100%;
    padding: 10px 20px 20px 20px;
    background: #fff;
    .title {
      font-size: 20px;
      font-weight: 550;
      background: #fff;
      padding: 10px 0px 0px 0px;
      color: #333;
    }
    .boxt {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #3a85fd;
      margin-right: 5px;
    }

    .titleBox {
      font-size: 18px;
      font-weight: 550;
      color: #333;
    }
  }
}
</style>
